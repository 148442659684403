var render = function () {
  var _vm$issuerOptions, _vm$campaignData, _vm$validation, _vm$validation$campai, _vm$validation2, _vm$validation2$campa, _vm$validation3, _vm$validation3$campa;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    key: _vm.reRender,
    staticClass: "form"
  }, [_c('sygni-loader', {
    attrs: {
      "isLoading": _vm.isLoading
    }
  }), _c('sygni-container-title', {
    attrs: {
      "legacyStyling": false
    }
  }, [_vm._v("General information")]), (_vm$issuerOptions = _vm.issuerOptions) !== null && _vm$issuerOptions !== void 0 && _vm$issuerOptions.length ? _c('sygni-select', {
    attrs: {
      "label": "ISSUER",
      "value": (_vm$campaignData = _vm.campaignData) === null || _vm$campaignData === void 0 ? void 0 : _vm$campaignData.issuerId,
      "options": _vm.issuerOptions,
      "validation": (_vm$validation = _vm.validation) === null || _vm$validation === void 0 ? void 0 : (_vm$validation$campai = _vm$validation.campaignData) === null || _vm$validation$campai === void 0 ? void 0 : _vm$validation$campai.issuerId,
      "disabled": !_vm.isEditable
    },
    on: {
      "input": _vm.setIssuer
    }
  }) : _vm._e(), _c('sygni-input', {
    attrs: {
      "label": "CAMPAIGN NAME",
      "validation": (_vm$validation2 = _vm.validation) === null || _vm$validation2 === void 0 ? void 0 : (_vm$validation2$campa = _vm$validation2.campaignData) === null || _vm$validation2$campa === void 0 ? void 0 : _vm$validation2$campa.campaignName,
      "disabled": !_vm.isEditable
    },
    on: {
      "blur": _vm.setCampaignName
    },
    model: {
      value: _vm.campaignData.campaignName,
      callback: function callback($$v) {
        _vm.$set(_vm.campaignData, "campaignName", $$v);
      },
      expression: "campaignData.campaignName"
    }
  }), _c('sygni-droparea', {
    attrs: {
      "supportedFileFormats": ['jpg', 'png', 'jpeg'],
      "preview": true,
      "previewUrl": _vm.imagePreviewUrl,
      "disabled": !_vm.isEditable
    },
    on: {
      "input": _vm.uploadThumbnail,
      "previewRemoved": _vm.removeImagePreview
    }
  }, [_c('sygni-rect-button', {
    staticClass: "outline gn-secondary",
    attrs: {
      "disabled": !_vm.isEditable
    }
  }, [_vm._v("Upload thumbnail image")]), _c('p', {
    staticClass: "label"
  }, [_vm._v("Optimal size: 1100x440px. Format: jpg, png")])], 1), _c('div', {
    staticClass: "title"
  }, [_vm._v("Headline")]), _c('div', {
    staticClass: "headline-group"
  }, [_c('div', {
    staticClass: "form__row"
  }, [_c('sygni-input', {
    attrs: {
      "label": "HEADLINE",
      "disabled": !_vm.isEditable
    },
    on: {
      "blur": _vm.setCampaignData
    },
    model: {
      value: _vm.headline[_vm.selectedLanguage],
      callback: function callback($$v) {
        _vm.$set(_vm.headline, _vm.selectedLanguage, $$v);
      },
      expression: "headline[selectedLanguage]"
    }
  })], 1), _c('div', {
    staticClass: "form__row"
  }, [_c('sygni-text-area', {
    attrs: {
      "label": "DESCRIPTION",
      "maxLength": 2000,
      "disabled": !_vm.isEditable
    },
    on: {
      "blur": _vm.setCampaignData
    },
    model: {
      value: _vm.description[_vm.selectedLanguage],
      callback: function callback($$v) {
        _vm.$set(_vm.description, _vm.selectedLanguage, $$v);
      },
      expression: "description[selectedLanguage]"
    }
  })], 1)]), _c('div', {
    staticClass: "title"
  }, [_vm._v("Website")]), _c('div', {
    staticClass: "form__row"
  }, [_c('sygni-input', {
    attrs: {
      "label": "WEBSITE LINK",
      "displayFormat": 'url',
      "validation": (_vm$validation3 = _vm.validation) === null || _vm$validation3 === void 0 ? void 0 : (_vm$validation3$campa = _vm$validation3.campaignData) === null || _vm$validation3$campa === void 0 ? void 0 : _vm$validation3$campa.landingPageUrl,
      "disabled": !_vm.isEditable
    },
    on: {
      "blur": _vm.setCampaignData
    },
    model: {
      value: _vm.campaignData.landingPageUrl,
      callback: function callback($$v) {
        _vm.$set(_vm.campaignData, "landingPageUrl", $$v);
      },
      expression: "campaignData.landingPageUrl"
    }
  })], 1), _c('div', {
    staticClass: "form-gallery"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("Gallery")]), _c('sygni-input', {
    attrs: {
      "label": "SECTION NAME",
      "disabled": !_vm.isEditable
    },
    on: {
      "blur": _vm.setCampaignData
    },
    model: {
      value: _vm.sectionNames[_vm.selectedLanguage].gallery,
      callback: function callback($$v) {
        _vm.$set(_vm.sectionNames[_vm.selectedLanguage], "gallery", $$v);
      },
      expression: "sectionNames[selectedLanguage].gallery"
    }
  }), _c('sygni-droparea', {
    attrs: {
      "multiple": true,
      "supportedFileFormats": ['jpg', 'png', 'jpeg', 'svg'],
      "disabled": !_vm.isEditable
    },
    on: {
      "change": _vm.addGalleryFiles
    }
  }, [_c('sygni-rect-button', {
    staticClass: "outline gn-secondary",
    attrs: {
      "disabled": !_vm.isEditable
    }
  }, [_vm._v("Upload new image")]), _c('p', {
    staticClass: "label"
  }, [_vm._v("Min height: 420px. Format: jpg, png, svg")])], 1), _vm.galleryPhotos.length ? _c('draggable', {
    staticClass: "form-gallery__container",
    model: {
      value: _vm.galleryPhotos,
      callback: function callback($$v) {
        _vm.galleryPhotos = $$v;
      },
      expression: "galleryPhotos"
    }
  }, _vm._l(_vm.galleryPhotos, function (photo, index) {
    return _c('div', {
      key: photo.id,
      staticClass: "form-gallery__photo"
    }, [_c('img', {
      attrs: {
        "src": photo.filePath,
        "alt": ""
      }
    }), _vm.isEditable ? _c('div', {
      staticClass: "form-gallery__photo-btn",
      on: {
        "click": function click($event) {
          return _vm.deleteGalleryPhoto(index);
        }
      }
    }, [_c('img', {
      attrs: {
        "src": _vm._f("getIcon")('DELETE'),
        "alt": ""
      }
    })]) : _vm._e()]);
  }), 0) : _vm._e()], 1), _c('div', {
    staticClass: "form__sections"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("Text sections")]), _vm._l(_vm.textSections[_vm.selectedLanguage], function (section, index) {
    return _c('div', {
      key: section.title,
      staticClass: "form-section"
    }, [_c('div', {
      staticClass: "form-section__name"
    }, [_c('div', {
      staticClass: "title"
    }, [_vm._v(_vm._s("Section ".concat(index + 1)))]), _vm.isEditable ? _c('div', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip",
        value: 'Delete section',
        expression: "'Delete section'"
      }],
      staticClass: "form-section__btn",
      on: {
        "click": function click($event) {
          return _vm.deleteTextSection(index);
        }
      }
    }, [_c('img', {
      attrs: {
        "src": _vm._f("getIcon")('DELETE'),
        "alt": ""
      }
    })]) : _vm._e()]), _c('div', {
      staticClass: "form-section__options"
    }, [_c('div', {
      staticClass: "form__row"
    }, [_c('div', {
      staticClass: "form__col"
    }, [_c('sygni-input', {
      attrs: {
        "label": "NAME",
        "value": section.title,
        "disabled": !_vm.isEditable
      },
      on: {
        "blur": function blur($event) {
          return _vm.handleSectionUpdate($event.target.value, section, 'title');
        }
      }
    })], 1), _c('div', {
      staticClass: "form__col"
    }, [_c('sygni-select', {
      attrs: {
        "label": "TYPE",
        "options": [{
          label: 'Full-width',
          value: 'wide'
        }, {
          label: '2 columns',
          value: '2-col'
        }],
        "allowEmpty": false,
        "disabled": !_vm.isEditable
      },
      on: {
        "input": function input($event) {
          _vm.reRender++;
        }
      },
      model: {
        value: section.type,
        callback: function callback($$v) {
          _vm.$set(section, "type", $$v);
        },
        expression: "section.type"
      }
    })], 1)])]), section.type === '2-col' ? _c('div', {
      staticClass: "form__row"
    }, [_c('div', {
      staticClass: "form__col"
    }, [_c('div', {
      staticClass: "form__editor"
    }, [_c('p', {
      staticClass: "label"
    }, [_vm._v("LEFT CONTENT")]), _c('editor-component', {
      attrs: {
        "layout": "clean",
        "content": section.leftContent,
        "readOnlyMode": !_vm.isEditable,
        "editable": _vm.isEditable
      },
      on: {
        "blur": function blur($event) {
          return _vm.handleSectionUpdate($event, section, 'left');
        }
      }
    })], 1)]), _c('div', {
      staticClass: "form__col"
    }, [_c('div', {
      staticClass: "form__editor"
    }, [_c('p', {
      staticClass: "label"
    }, [_vm._v("RIGHT CONTENT")]), _c('editor-component', {
      attrs: {
        "layout": "clean",
        "content": section.rightContent,
        "readOnlyMode": !_vm.isEditable,
        "editable": _vm.isEditable
      },
      on: {
        "blur": function blur($event) {
          return _vm.handleSectionUpdate($event, section, 'right');
        }
      }
    })], 1)])]) : _c('div', {
      staticClass: "form__row"
    }, [_c('div', {
      staticClass: "form__editor"
    }, [_c('p', {
      staticClass: "label"
    }, [_vm._v("CONTENT")]), _c('editor-component', {
      attrs: {
        "layout": "clean",
        "content": section.leftContent,
        "readOnlyMode": !_vm.isEditable,
        "editable": _vm.isEditable
      },
      on: {
        "blur": function blur($event) {
          return _vm.handleSectionUpdate($event, section, 'left');
        }
      }
    })], 1)])]);
  }), _c('sygni-rect-button', {
    class: ['bordered', !_vm.isEditable ? 'disabled' : ''],
    attrs: {
      "disabled": !_vm.isEditable
    },
    on: {
      "click": _vm.addNewTextSection
    }
  }, [_vm._v("Add text section +")])], 2), _c('div', {
    staticClass: "form-rodo"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("Consent data")]), _c('sygni-input', {
    attrs: {
      "label": "CONTENT OF THE CONSENT (PL)",
      "disabled": !_vm.isEditable
    },
    on: {
      "blur": _vm.setCampaignData
    },
    model: {
      value: _vm.consentData.content,
      callback: function callback($$v) {
        _vm.$set(_vm.consentData, "content", $$v);
      },
      expression: "consentData.content"
    }
  })], 1), _c('GenproxModal', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.issuerIdToChange,
      expression: "issuerIdToChange"
    }],
    attrs: {
      "header": "Are you sure?",
      "description": "When changing the issuer, the agreement template field will be cleared. Do you want to change it anyway?",
      "cancelText": "No",
      "confirmText": "Yes"
    },
    on: {
      "cancel": _vm.closeIssuerChangeModal,
      "close": _vm.closeIssuerChangeModal,
      "confirm": _vm.confirmIssuerChangeModal
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }